export default {
	routes: {
		public: [
			{ path: "/login", name: "Login", exact: true },
			{ path: "/forgot-password", name: "ForgotPassword", exact: true },
			{
				name: "ResetPassword",
				path: "/reset-password/:token",
				exact: true,
			},
		],
		private: [
			{
				path: "/dashboard",
				name: "Dashboard",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/admin/change-password",
				name: "Change Password",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/admin/master-data",
				name: "Master Data",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Vehicle Type",
				path: "/admin/master-data/vehicle-type",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Vehicle Color",
				path: "/admin/master-data/vehicle-color",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},

			{
				name: "Vehicle Fuel Type",
				path: "/admin/master-data/vehicle-fuel-type",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Vehicle Asset Owner",
				path: "/admin/master-data/vehicle-asset-owner",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Vehicle Park Owner",
				path: "/admin/master-data/carpark-owner",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Export File Password",
				path: "/admin/master-data/export-file-password",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Vehicle Make",
				path: "/admin/master-data/vehicle-make",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Car Model",
				path: "/admin/master-data/car-model",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Public Holiday",
				path: "/admin/master-data/public-holiday",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Promotional Day",
				path: "/admin/master-data/promotional-day",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Vehicle Photo",
				path: "/admin/master-data/vehicle-photos",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Create Vehicle Photo",
				path: "/admin/master-data/vehicle-photos/create",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Edit Vehicle Photo",
				path: "/admin/master-data/vehicle-photos/edit/:id",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Contact Us Topic",
				path: "/admin/master-data/contact-us-topic",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Fuel Company",
				path: "/admin/master-data/fuel-company",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},

			// {
			// 	name: "Notification Management",
			// 	path: "/notification-management",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			[
			// 				"admin",
			// 				"marketing",
			// 				"customer experience",
			// 				"trust and safety",
			// 				"cx lead",
			// 			],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Edit Notification",
			// 	path: "/notification-management/edit/:id",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			[
			// 				"admin",
			// 				"marketing",
			// 				"customer experience",
			// 				"trust and safety",
			// 				"cx lead",
			// 			],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Create Notification",
			// 	path: "/notification-management/create",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			[
			// 				"admin",
			// 				"marketing",
			// 				"customer experience",
			// 				"trust and safety",
			// 				"cx lead",
			// 			],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			{
				name: "Announcement",
				path: "/admin/announcement",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Edit Announcement",
				path: "/admin/announcement/edit/:id",
				exact: true,
				config: { policies: [["admin"], ["*"]] },
			},
			{
				name: "Create Announcement",
				path: "/admin/announcement/create",
				exact: true,
				config: { policies: [["admin"], ["*"]] },
			},
			{
				name: "Price Group Management",
				path: "/price-management",
				exact: true,
				config: {
					policies: [
						["admin", "finance"],
						["read", "write"],
					],
				},
			},
			{
				name: "Create Price Management",
				path: "/price-management/create",
				exact: true,
				config: {
					policies: [
						["admin", "finance"],
						["read", "write"],
					],
				},
			},
			{
				name: "Edit Price Management",
				path: "/price-management/edit/:id",
				exact: true,
				config: {
					policies: [
						["admin", "finance"],
						["read", "write"],
					],
				},
			},
			// {
			// 	name: "Carpark Management",
			// 	path: "/carpark-management",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Create Carpark",
			// 	path: "/carpark-management/create",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Carpark Details",
			// 	path: "/carpark-management/details/:id",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Edit Carpark",
			// 	path: "/carpark-management/edit/:id",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Create Parking Lot",
			// 	path: "/carpark-management/:carParkId/parking-lot/create",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Edit Parking Lot",
			// 	path: "/carpark-management/:carParkId/parking-lot/edit/:id",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Car Management",
			// 	path: "/car-management",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations", "cx lead"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Fuel Card Management",
			// 	path: "/fuel-card-management",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			[
			// 				"admin",
			// 				"operations",
			// 				"customer experience",
			// 				"trust and safety",
			// 				"cx lead",
			// 			],
			// 			["read, write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Create Fuel Card",
			// 	path: "/fuel-card-management/create",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			[
			// 				"admin",
			// 				"operations",
			// 				"customer experience",
			// 				"trust and safety",
			// 				"cx lead",
			// 			],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Edit Fuel Card",
			// 	path: "/fuel-card-management/edit/:id",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			[
			// 				"admin",
			// 				"operations",
			// 				"customer experience",
			// 				"trust and safety",
			// 				"cx lead",
			// 			],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			{
				name: "Account Management",
				path: "/account-management/manage",
				exact: true,
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read, write"],
					],
				},
			},
			{
				name: "Mass Ban",
				path: "/account-management/mass-ban",
				exact: true,
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read, write"],
					],
				},
			},
			{
				name: "Mass Unban",
				path: "/account-management/mass-unban",
				exact: true,
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read, write"],
					],
				},
			},
			{
				name: "My Account",
				path: "/account-management/my-account",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "My Inbox",
				path: "/account-management/my-inbox",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			// {
			// 	name: "Create Car Management",
			// 	path: "/car-management/create",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations", "cx lead"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			{
				name: "Account Details",
				path: "/account-management/manage/details/:id",
				exact: true,
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read", "write"],
					],
				},
			},
			{
				name: "Account Log",
				path: "/account-management/details/log/:id",
				exact: true,
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read", "write"],
					],
				},
			},
			// {
			// 	name: "Car Detail",
			// 	path: "/car-management/:id",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations", "cx lead"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			{
				name: "Edit Profile",
				path: "/account-management/manage/edit/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			// {
			// 	name: "Edit Car Management",
			// 	path: "/car-management/edit/:id",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations", "cx lead"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Blackout Management",
			// 	path: "/blackout-management",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations", "cx lead"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Create Blackout Management",
			// 	path: "/blackout-management/create",
			// 	exact: true,
			// 	config: {
			// 		policies: [
			// 			["admin", "operations", "cx lead"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			// {
			// 	name: "Edit Blackout Management",
			// 	path: "/blackout-management/edit/:id",
			// 	config: {
			// 		policies: [
			// 			["admin", "operations", "cx lead"],
			// 			["read", "write"],
			// 		],
			// 	},
			// },
			{
				name: "Audit Trails",
				path: "/admin/audit-trails",
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Create Promo Code",
				path: "/promo-code-management/create",
				exact: true,
				config: {
					policies: [
						["admin", "marketing", "cx lead"],
						["read", "write"],
					],
				},
			},
			{
				name: "Edit Promo Code",
				path: "/promo-code-management/edit/:id",
				exact: true,
				config: {
					policies: [
						["admin", "marketing", "cx lead"],
						["read", "write"],
					],
				},
			},
			{
				name: "Duplicate Promo Code",
				path: "/promo-code-management/duplicate/:id",
				exact: true,
				config: {
					policies: [
						["admin", "marketing", "cx lead"],
						["read", "write"],
					],
				},
			},
			{
				name: "Promo Code Management",
				path: "/promo-code-management",
				exact: true,
				config: {
					policies: [
						["admin", "marketing", "cx lead"],
						["read", "write"],
					],
				},
			},
			{
				name: "Booking Receipt",
				path: "/booking-management/details/:id/receipt",
				exact: true,
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read", "write"],
					],
				},
			},
			{
				name: "Booking Details",
				path: "/booking-management/details/:id",
				exact: true,
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read", "write"],
					],
				},
			},
			{
				name: "Booking Management",
				path: "/booking-management",
				exact: true,
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read", "write"],
					],
				},
			},
			{
				name: "Create Booking Management",
				path: "/booking-management/create",
				exact: true,
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read", "write"],
					],
				},
			},
			{
				name: "Contact Us Management",
				path: "/Contact-Us-management",
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"marketing",
							"trust and safety",
							"cx lead",
						],
						["read", "write"],
					],
				},
			},
			{
				name: "Transaction Management",
				path: "/transaction-management",
				config: {
					policies: [
						[
							"admin",
							"customer experience",
							"trust and safety",
							"cx lead",
						],
						["read", "write"],
					],
				},
			},
			{
				name: "Cms Account Management",
				path: "/admin/cms-accounts",
				exact: true,
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
			{
				name: "Create Cms Account Management",
				path: "/admin/cms-accounts/create",
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},

			{
				name: "Edit Cms Account Management",
				path: "/admin/cms-accounts/edit/:id",
				config: {
					policies: [["admin"], ["read", "write"]],
				},
			},
		],
	},
};
